<template>
  <div v-if="isMobile" class="home" ref="homes">
    <div>
      <div class="content" ref="contentBox">
        <!--      首页-->

        <div class="first">
          <img :src="constUrl+homeItem.picture" :key="constUrl+homeItem.picture"/>
          <div class="nav-box">
            <div class="title">
              <p>{{ homeItem.title }}
              </p>
              <span>{{ homeItem.subtitle }}</span>
              <el-button @click="toCompany">{{ homeItem.detail }}</el-button>
            </div>
          </div>
        </div>
        <!--          公司简介-->
        <div class="introduce">
          <div class="introduceTitle ">
            <h3>{{ companyItem.title }}</h3>
            <h4>{{ companyItem.subtitle }}</h4>
            <div style="text-indent: 2em" class="detailBox">
              {{ companyItem.detail }}
            </div>
          </div>
          <div class="introduceImg">
            <img :src="constUrl+companyItem.picture" :key="constUrl+companyItem.picture"/>
          </div>
          <div class="introduceFooter">
            <el-row :gutter="10">
              <el-col :span="8" v-for="item in companyItem.itemList"
                      :key="item.id">
                <h3>{{ item.name }}</h3><img v-lazy="constUrl+item.picture" :key="constUrl+item.picture"/>
                <p> {{ item.detail }}</p>
              </el-col>
            </el-row>
          </div>
        </div>

        <!--          云平台与解决方案-->
        <div class="introduce">
          <div class="introduceImg2">
            <img v-lazy="constUrl+productItem.picture" :key="constUrl+productItem.picture"/>
          </div>
          <div class="introduceTitle ">
            <h3>{{ productItem.name }}</h3>
            <h4>{{ productItem.subtitle }}</h4>
            <div style="text-indent: 2em">
              {{ productItem.detail }}
            </div>
          </div>
          <div class="introduceFooter2">
            <ul
              style="height: 100%;width: 100%;display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap">
              <li v-for="item in productItem.itemList" :key="item.id" style="width: 25%">
                <h3>{{ item.name }}</h3> <img :src="constUrl+item.picture" :key="constUrl+item.picture"/>
                <p> {{ item.title }}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <!--          产品中心-->
        <div class="product_center">
          <div class="productTitle ">
            <h3>{{ cloudItem.name }}</h3>
            <h4>Product Center</h4>
          </div>

          <div class="productBox">
            <el-carousel height="80vw" arrow="always" :autoplay="false">
              <el-carousel-item v-for="(item,index) in cloudItem.itemList" :key="index" style="text-align: center">
                <!--                                  @click.native="toDetails(1476724395989843970)"-->
                <img style="height: 80%;margin-top: 5%" :src="constUrl+item.picture" :key="constUrl+item.picture"/>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <!--          成功案例-->

        <div class="application" >
          <div class="applicationTitle ">
            <h3>{{ caseItem.name }}</h3>
            <h4>{{ caseItem.subtitle }}</h4>
          </div>
          <div class="applicationContent"  v-for="(item,index) in caseItem.itemList" :key="item.name"
                style="border-radius: 20px">
            <div class="applicationCard " :class="'applicationCard'+index">
              <div class="applicationCardPic">
                <img v-lazy="constUrl+item.picture" :key="constUrl+item.picture"/>
              </div>
              <div class="applicationCardText">
                <h3>{{ item.name }}</h3>
                <p>{{ item.detail }}</p>
                <span @click="toCases">了解更多 >></span>
              </div>
            </div>
          </div>
        </div>

        <!--          联系我们-->
          <div class="contactUs">
            <div class="contactUsTitle">
              <h3>联系我们</h3>
              <h4>Contact Us</h4>
            </div>
            <div class="contactUsContent">
              <div class="contactUsContentTop">
                <p>联系地址：</p>
                <div style="width: 25px;height: 2px;background: #0d9efa;margin-bottom: 10px"></div>
                <p style="margin-bottom:20px">北京大兴区金星路18号院6幢1层1111</p>
                <p>联系方式：</p>
                <div style="width: 25px;height: 2px;background: #0d9efa;margin-bottom: 10px"></div>
                <p style="border-bottom:dashed 1px #303133;width: 60%;margin-bottom: 30px">Tel：18984015204</p>
                <p style="border-bottom:dashed 1px #303133;width: 60%;margin-bottom: 30px">Web：www.jiugankeji.com</p>
                <p style="border-bottom:dashed 1px #303133;width: 60%;margin-bottom: 30px">Email：10802561@qq.com</p>
              </div>
              <div class="contactUsContentMap">
                <baidu-map class="map" ak="Xv866NcNqsH2MlOweVUjPBMObi2ECYjQ"
                                 :scroll-wheel-zoom="true"
                                 :center="center"
                                 :zoom="zoom"   
                                @ready="handler">
                </baidu-map>
              </div>

              <div class="contactUsFooter"
                   style="width: 60%;margin-left: 20%;margin-top:5%;height: 40%;text-align: left">
                <p>在线留言</p>
                <div style="width: 25px;height: 2px;background: #0d9efa;margin-bottom: 10px"></div>
                <el-input placeholder="姓 名："></el-input>
                <el-input placeholder="电 话："></el-input>
                <el-input placeholder="邮 箱："></el-input>
                <textarea rows="5" placeholder="留言:" style="margin-bottom: 2vw;width: 100%;"></textarea>
                <el-button @click="submitLeaving" style="background: #0D9EFA;color: white;margin-left: 20%"> 提交留言</el-button>
              </div>

            </div>
          </div>
      </div>
    </div>
    <footer-box v-if="isMobile"   class="footerBoxAll" style="z-index: 999" ></footer-box>

  </div>

  <!--  pc端样式-->
  <div v-else class="pcBox home" ref="homes">
    <div style="width: 100%;height: 100%;position: absolute;top: 0px;left: 0">
      <div class="divBox">
        <div class="navigationBox">
          <ul>
            <li v-for="(item,index) in navigationData" :key="item.name" :class="liActive==index?'liActive':''"
                @click="toLiData(item,index)">{{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="content" ref="contentBox">
        <!--      首页-->
        <transition name="move">
          <div v-show="liActive==0" class="first">
            <!--              <video autoplay loop muted="muted" src="../assets/bgview3.mp4"></video>&lt;!&ndash; 视频背景图就这一行 &ndash;&gt;-->
            <canvas id="Mycanvas"></canvas>
            <img :src="constUrl+homeItem.picture" :key="constUrl+homeItem.picture"/>
            <div class="nav-box">
              <div class="title">
                <p>{{ homeItem.title }}
                </p>
                <span>{{ homeItem.subtitle }}</span>
                <el-button @click="toCompany">{{ homeItem.detail }}</el-button>
              </div>
            </div>
          </div>
        </transition>
        <!--          公司简介-->
        <transition name="move">
          <div v-show="liActive==1" class="introduce" :class="isDownAnimition==true ?'down_animition':' '">
            <el-row>
              <el-col :span="14">
                <div class="introduceTitle ">
                  <h3>{{ companyItem.title }}</h3>
                  <h4>{{ companyItem.subtitle }}</h4>
                  <div style="text-indent: 2em" class="detailBox">
                    {{ companyItem.detail }}
                  </div>
                </div>
              </el-col>
              <el-col :span="10">
                <div class="introduceImg">
                  <img :src="constUrl+companyItem.picture" :key="constUrl+companyItem.picture"/>
                </div>
              </el-col>
            </el-row>
            <div class="introduceFooter">
              <el-row :gutter="60">
                <el-col :span="8" v-for="item in companyItem.itemList"
                        :key="item.id">
                  <h3>{{ item.name }}</h3><img v-lazy="constUrl+item.picture" :key="constUrl+item.picture"/>
                  <p> {{ item.detail }}</p>
                </el-col>
              </el-row>
            </div>
          </div>
        </transition>
        <!--          云平台与解决方案-->
        <transition name="move">
          <div v-show="liActive==2" class="introduce" :class="isDownAnimition==true ?'down_animition':' '">
            <el-row>
              <el-col :span="10">
                <div class="introduceImg2">
                  <img v-lazy="constUrl+productItem.picture" :key="constUrl+productItem.picture"/>
                </div>
              </el-col>
              <el-col :span="14">
                <div class="introduceTitle " style="text-indent: 2em">
                  <h3>{{ productItem.name }}</h3>
                  <h4>{{ productItem.subtitle }}</h4>
                  <div>
                    {{ productItem.detail }}
                  </div>
                </div>
              </el-col>
            </el-row>
            <div class="introduceFooter2">
              <ul
                style="height: 100%;width: 100%;display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap">
                <li v-for="item in productItem.itemList" :key="item.id" style="width: 16%">
                  <h3>{{ item.name }}</h3> <img :src="constUrl+item.picture" :key="constUrl+item.picture"/>
                  <p> {{ item.title }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </transition>
        <!--          产品中心-->
        <transition name="move">
          <div v-show="liActive==3" class="product_center">
            <div class="productBox">
              <el-carousel height="100vh" arrow="always" :autoplay="false">
                <el-carousel-item v-for="(item,index) in cloudItem.itemList" :key="index" style="text-align: center">
                  <!--                                  @click.native="toDetails(1476724395989843970)"-->
                  <img style="height: 80%;margin-top: 5%" :src="constUrl+item.picture" :key="constUrl+item.picture"/>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </transition>
        <!--          成功案例-->
        <transition name="move">
          <div v-show="liActive==4" class="application" :class="isDownAnimition==true ?'down_animition':' '">
            <div class="applicationTitle ">
              <h3>{{ caseItem.name }}</h3>
              <h4>{{ caseItem.subtitle }}</h4>
            </div>
            <div class="applicationBg">
              <!--              <img :src="constUrl+caseItem.picture" style="height: 100%;width: 100%"/>-->
              <el-row :gutter="20" style="width: 60%;height: 125%;">
                <el-col :span="8" v-for="(item,index) in caseItem.itemList" :key="item.name"
                        style="border-radius: 20px">
                  <div class="applicationCard " :class="'applicationCard'+index">
                    <div class="applicationCardPic">
                      <img v-lazy="constUrl+item.picture" :key="constUrl+item.picture"/>
                    </div>
                    <div class="applicationCardText">
                      <h3>{{ item.name }}</h3>
                      <p>{{ item.detail }}</p>
                      <span @click="toCases">了解更多 >></span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </transition>
        <!--          联系我们-->
        <transition name="smove">
          <div v-show="liActive==5" class="contactUs">
            <div class="contactUsTitle">
              <h3>联系我们</h3>
              <h4>Contact Us</h4>
            </div>
            <div class="contactUsContent">
              <el-row style="width: 60%;margin-left: 20%;height: 40%;text-align: left" :gutter="65">
                <el-col :span="8">
                  <p>联系地址：</p>
                  <div style="width: 25px;height: 2px;background: #0d9efa;margin-bottom: 10px"></div>
                  <p style="margin-bottom:20px">北京大兴区金星路18号院6幢1层11111</p>
                  <p>联系方式：</p>
                  <div style="width: 25px;height: 2px;background: #0d9efa;margin-bottom: 10px"></div>
                  <p style="border-bottom:dashed 1px #303133;width: 60%;margin-bottom: 30px">Tel：18984015204</p>
                  <p style="border-bottom:dashed 1px #303133;width: 60%;margin-bottom: 30px">Web：www.jiugankeji.com</p>
                  <p style="border-bottom:dashed 1px #303133;width: 60%;margin-bottom: 30px">Email：10802561@qq.com</p>
                </el-col>
                <el-col :span="16">
                  <baidu-map class="map" ak="Xv866NcNqsH2MlOweVUjPBMObi2ECYjQ"
                                   :scroll-wheel-zoom="true"
                                   :center="center"
                                   :zoom="zoom"   
                                  @ready="handler">
                  </baidu-map>
                </el-col>
              </el-row>
              <div class="contactUsFooter"
                   style="width: 60%;margin-left: 20%;margin-top:5%;height: 40%;text-align: left">
                <p>在线留言</p>
                <div style="width: 25px;height: 2px;background: #0d9efa;margin-bottom: 10px"></div>
                <el-row :gutter="65">
                  <el-col :span="8">

                    <el-input placeholder="姓 名："></el-input>
                    <el-input placeholder="电 话："></el-input>
                    <el-input placeholder="邮 箱："></el-input>
                  </el-col>
                  <el-col :span="16">
                    <textarea></textarea>
                    <el-button @click="submitLeaving"> 提交留言</el-button>
                  </el-col>
                </el-row>
              </div>

            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
  // <!-- debounce防抖-->
  import {debounce} from '@/utils/debounce'
  import {
    websiteLeaveMessage,
  } from '@/services/model/website'
  import Bus from '@/utils/Bus'
  import canvasInit from './canvas'
  import ItemMixins from "./item.mixins";
  export default {
    mixins: [ItemMixins],
    data() {
      return {
        center: {lng: 116.355015364, lat: 39.761584},
        dataList: [],
        zoom: 3,
        //提交参数
        currentRow: {},
        liActive: 0,
        navigationData: [],
        show: 1,
        isDownAnimition: false,
        isTabActive: 0,
        homeItem: {},
        companyItem: {},
        cloudItem: {},
        productItem: {},
        caseItem: {},
        id: ''
      }
    },
    mounted() {
      this.init()
      let canvas = document.getElementById('Mycanvas')
      let context = canvas.getContext('2d')
      canvasInit(canvas, context)
      this.$refs.contentBox.addEventListener('mousewheel', debounce(this.handleScroll), true);
    }
    ,
    beforeRouteLeave(to, from, next) {
      //离开当前组件前一定要清除滚动监听，否则进入其他路由会报错
      window.removeEventListener('mousewheel', this.handleScroll, true)
      next()
    },
    methods: {
      //获取home页数据
      async init() {
        await this.getHeadMenuList()
        let list = await this.getChildList()
        this.navigationData = list
        this.homeItem = list[0]
        this.companyItem = list[1]
        this.productItem = list[2]
        this.cloudItem = list[3]
        this.caseItem = list[4]
      },
      //鼠标滚动事件
      handleScroll(e) {
        // 判断滚动方向
        // return
        let direction = e.deltaY > 0 ? 'down' : 'up';  //deltaY为正则滚轮向下，为负滚轮向上
        if (direction == 'down' || e.deltaY == 100) { //125为用户一次滚动鼠标的wheelDelta的值
          if (this.liActive >= 5) {
            this.liActive = 5
            //控制底部栏 让其可以展示出来
            this.$emit('footerBox', 1)
          } else {
            this.liActive++
            //控制底部栏 让其滚动的时候不会上移动
            this.$emit('footerBox', 2)
          }
        }
        if (direction == 'up' && e.deltaY == -100) {
          if (this.liActive <= 0) {
            this.liActive = 0
          } else {
            this.liActive--
          }
        }
      },
      //提交留言
      async submitLeaving() {
        if (JSON.stringify(this.currentRow) == "{}") {
          this.$notify({
            type: 'warning',
            title: '提示',
            message: '请填写信息！'
          })
        }
        const {res} = await websiteLeaveMessage(this.currentRow)
        if (res?.code === 200) {
          this.$notify({
            type: 'success',
            title: '提示',
            message: '提交成功！'
          })
        }
      },
      //了解详情跳转
      toCases() {
        this.$nextTick(function () { //解决第一次监听不到数据
          //解决页面跳转后导航栏样式修改
          Bus.$emit("toCompany", 3, "子组件向兄弟组件传值");    //存 Bus.$emit
        })
        sessionStorage.setItem("navID", '1475756915687473153');
        this.$router.push({path: '/successful-cases', query: {forms: 'home'}}); // 路径名name
      },
      //跳转到公司简介
      toCompany() {
        this.$nextTick(function () { //解决第一次监听不到数据
          //解决页面跳转后导航栏样式修改
          Bus.$emit("toCompany", 1, "子组件向兄弟组件传值");    //存 Bus.$emit
        })
        sessionStorage.setItem("navID", '1475754914647949314');
        this.$router.push({path: '/about-company', query: {forms: 'home'}}); // 路径名name
      },
      //右边导航栏
      toLiData(item, index) {
        this.liActive = index
      },
      //百度地图
      handler({BMap, map}) {
        this.center.lng = 116.355015364
        this.center.lat = 39.761584
        this.zoom = 15
      },
    },

  }
</script>
<style lang="less" scoped>
  #Mycanvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
  }

  .detailBox {
    font-style: normal;
  }

  .navigationBox {
    height: 300px;
    position: fixed;
    right: 50px;
    top: 50vh;
    transform: translateY(-150px);
    z-index: 999;

    ul {
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      li {
        cursor: pointer;
        transition: font-size 0.3s ease-in-out;
        width: 100%;
        height: 80px;
        font-size: 15px;
        color: white;
        position: relative;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
      }

      li:after {
        content: '';
        width: 10px;
        height: 10px;
        border: 3px solid white;
        background: transparent;
        border-radius: 50%;
        position: absolute;
        right: -30px;
        margin-top: 4px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
      }

      .liActive {
        width: 100%;
        height: 80px;
        font-size: 16px;
        font-weight: 600;
        position: relative;
        color: #3d3d3d;
        transform: scale(1.1);
        transition: all 0.4s;
        text-shadow: 0px 0px 2px #ffffff;
      }

      .liActive:after {
        content: '';
        width: 15px;
        height: 15px;
        border: 3px solid #0d9efa;
        background: transparent;
        border-radius: 50%;
        position: absolute;
        right: -22px;
        margin-top: 4px;
        box-shadow: 0px 0px 4px rgba(13, 158, 250, 1);
      }

    }
  }
</style>
<style scoped lang="less">


</style>
<style lang="less">
  .pcBox {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: hidden;
    .content {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  /*第一页*/
  .first {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .nav-box {
    width: 100%;
    height: 100vh;
  }


  .title {
    width: 80%;
    height: 25%;
    position: absolute;
    bottom: -20%;
    left: 2%;
    text-align: left;
    -webkit-animation: title 1s ease forwards;
    @keyframes title {
      0% {
        bottom: 25%;
        opacity: 0;
      }
      100% {
        bottom: 45%;
        opacity: 1;
      }
    }

    p {
      font-weight: 600;
      font-size: 500%;
      color: white;
      margin: 0 auto;
    }

    span {
      margin-top: 20px;
      font-size: 16px;
      margin-left: 20px;
      display: block;
      color: #ffffff;
    }


    .el-button {
      margin-top: 1%;
      width: 25%;
      height: 25%;
      font-size: 180%;
      color: white;
      /*兼容问题*/
      background: linear-gradient(to right, #0D9EFA, #0df2fa);
      background: -moz-linear-gradient(to right, #0D9EFA, #0df2fa);
      background: -webkit-gradient(to right, #0D9EFA, #0df2fa);
      background: -webkit-linear-gradient(to right, #0D9EFA, #0df2fa);
      background: -o-linear-gradient(to right, #0D9EFA, #0df2fa);
      background: -ms-linear-gradient(to right, #0D9EFA, #0df2fa);
      background: linear-gradient(to right, #0D9EFA, #0df2fa);
      border: none;
    }
  }

  /*  第二页*/
  .introduce {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 10% 10%;
    overflow: hidden;

    .el-row {
      height: 70%;
    }

    .introduceTitle {
      transform: translateX(-280px);
      -webkit-animation: leftBox 2s ease 1 forwards;
      opacity: 1;


      h3 {
        font-size: 30px;
        font-weight: 600;
        color: #25282B;
      }

      h4 {
        font-size: 10px;
        font-weight: 600;
        color: #25282B;
      }

      div {
        margin-top: 60px;
        line-height: 40px;
        font-size: 20px;
        color: black;
        font-weight: 200;
        width: 80%;
      }

    }

    .el-col-10 {
      height: 100%;

      .introduceImg {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;

        img {
          height: 100%;
        }
      }

      .introduceImg2 {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 68%;

        img {
          width: 100%;
        }
      }

    }

    .introduceFooter {
      margin-top: 50px;
      width: 100%;
      height: 50%;
      transform: translateY(50vh);
      -webkit-animation: topBox 1.2s ease-in 1 forwards;
      -webkit-animation-delay: 1.2s;

      /*background: #0df2fa;*/

      h3 {
        font-size: 60px;
        font-weight: 700;
        line-height: 80px;
        height: 80px;
        display: inline-block;
        color: #d4d4d4;

      }

      img {
        width: 65px;
        margin-left: 30px;
        display: inline-block;
        margin-top: -35px;
      }

      .el-col-8 {
        transform: scale(1);
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0);
        transition: transform 0.5s;
      }

      .el-col-8:hover {

        transform: scale(1.1);
        /*box-shadow: 0px 0px 5px  rgba(0,0,0,1);*/
      }

    }

    .introduceFooter2 {
      margin-top: 50px;
      width: 100%;
      height: 50%;

      h3 {
        font-size: 20px;
        font-weight: 700;
        line-height: 80px;
        height: 60px;
        display: inline-block;
        color: #d4d4d4;
      }

      img {
        width: 50px;
        height: 45px;
        margin-left: 10px;
        display: inline-block;
        margin-top: -35px;
      }

      li {
        transform: scale(1);
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0);
        transition: transform 0.5s;
      }

      li:hover {

        transform: scale(1.2);
        /*box-shadow: 0px 0px 5px  rgba(0,0,0,1);*/
      }

    }
  }

  /*  第 四页*/
  .product_center {
    width: 100vw;
    height: 100vh;
    overflow: hidden;


    .productBox {
      width: 100vw;
      height: 100vh;
      margin: auto;
      box-sizing: border-box;

      /deep/ .el-carousel__indicator--horizontal {
        display: inline-block;
        padding: 12px 4px;

        /deep/ .el-carousel__button {
          width: 40px;
        }
      }

      /*background: white;*/
      position: relative;


      .productPicBox {
        width: 100%;
        height: 100%;
        background: #0df2fa;
        margin-top: 50px;
        overflow: hidden;

        ul {
          width: 100%;
          height: 100%;

          li {
            height: 100%;
            width: 100%;
          }

        }

      }

      .productPicTitle {
        width: 20%;
        height: 55%;
        position: absolute;
        right: 10%;
        bottom: 13%;
        overflow: hidden;


        productPicTitleBox {
          width: 100%;
          height: 100%;
          background: #0df2fa;
          overflow: auto;

          .el-card {
            width: 100%;
            height: 22%;
            margin-bottom: 5%;

            h3 {
              font-size: 18px;
              font-weight: 600;
            }

            p {
              font-weight: 400;
              font-size: 14px;
              color: #747474;
            }

          }
        }

      }
    }
  }

  /*  第五页*/
  .application {
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .applicationTitle {
      width: 80%;
      height: 10%;
      margin-top: 8%;
      margin-left: 10%;

      h3 {
        font-size: 30px;
        color: #25282b;
        font-weight: 600;
      }

      h4 {
        font-size: 15px;
        color: #25282b;
        margin-bottom: 20px;
      }

    }

    .applicationBg {
      width: 100%;
      height: 60%;
      background-size: 100%;
      position: relative;
      background-image: linear-gradient(to bottom, #ffffff, #1a1a1a);

      .el-row {
        position: absolute;
        left: 20%;
        bottom: -20%;

      }

      .el-col {
        height: 90%;
        position: relative;

      }

      .applicationCard {
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 10px #636363;
        background: #fff;
        border-radius: 20px;


        .applicationCardPic {
          width: 100%;
          height: 78%;
          box-sizing: border-box;
          overflow: hidden;
          border-radius: 20px 20px 0 0;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .applicationCardText {
          width: 90%;
          margin: auto;
          height: 27%;
          overflow: hidden;

          h3 {
            font-size: 18px;
            color: #292929;
            font-weight: 600;
            text-align: center;
            margin-top: 2%;
          }

          p {
            text-indent: 2em;
            font-size: 14px;
            line-height: 20px;
            color: #25282b;
            margin-top: 5px;
            width: 100%;
            height: 40px;
            text-indent: 2em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          span {
            margin-top: 1%;
            display: inline-block;
            margin-left: 70%;
            color: #43d5fd;
            text-align: right;
            cursor: pointer;
          }
        }

      }

    }

  }

  /*  第六页*/
  .contactUs {
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .contactUsTitle {
      width: 80%;
      height: 10%;
      margin-top: 8%;
      margin-left: 10%;
      text-align: center;

      h3 {
        font-size: 30px;
        color: #25282b;
        font-weight: 600;
      }

      h4 {
        font-size: 15px;
        color: #25282b;
        margin-bottom: 20px;
      }

    }

    .contactUsContent {
      width: 100%;
      height: 70%;

      .contactUsFooter {

        input {
          margin-bottom: 20px;
          background: none;
          border: 1px solid #4e4e4e;

        }

        .el-col-16 {
          /*width: 100%;*/
          height: 290px;

          textarea {
            width: 100%;
            height: 60%;
            background: none;
            margin-bottom: 30px !important;
            border: 1px solid #4e4e4e;

          }

          .el-button {
            background: #0d9efa;
            color: white;
            font-weight: 600;
            font-size: 120%;
            border: none;
          }

        }
      }
    }
  }
</style>
<!--界面动画 效果-->
<style lang="less">
  /*从下往上*/
  .down_animition {
    animation: down_animition 2s;

  }

  @keyframes leftBox {
    0% {
      transform: translateX(-280px);
      opacity: 0;
    }
    50% {
      transform: translateX(-140px);
      opacity: 0.5;
    }
    80% {
      transform: translateX(20px);
      opacity: 1;
    }
    90% {
      transform: translateX(0px);
      opacity: 1;
    }
    95% {
      transform: translateX(16px);
      opacity: 1;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }

  }

  @keyframes topBox {
    0% {
      transform: translateY(50vh);
      opacity: 0;
    }
    50% {
      transform: translateY(0vh);
      opacity: 1;
    }
    80% {
      transform: translateY(10vh);
      opacity: 1;
    }
    90% {
      transform: translateY(0px);
      opacity: 1;
    }
    95% {
      transform: translateY(5vh);
      opacity: 1;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }


  }

  @keyframes down_animition {
    from {
      transform: translateY(180px);
    }
    to {
      transform: translateY(0px);
    }
  }

  /*从左往右*/
  .left_animition {
    animation: left_animition 2s;

  }

  @keyframes left_animition {
    from {
      transform: translatex(-180px);
    }
    to {
      transform: translatex(0px);
    }
  }

  .map {
    width: 100%;
    height: 270px;
    border: 1px solid black;
  }
</style>

<style scoped lang="less">
  /*手机样式*/
  .phoneBox {
    width: 100vw;
    padding-bottom: 20vw;
    overflow: auto;
    height: 100%;
    .content {
      width: 100vw;
      height: 100%;
      overflow: auto;

      padding-bottom: 25vw;

    }
    .home {
      padding-bottom: 00vw;
      overflow: auto;
    }

    .first {
      width: 100vw;
      height: 70vw;
    }

    .nav-box {
      .title {
        text-align: left;
        width: 70%;

        p {
          font-size: 4vw;
        }

        span {
          font-size: 3vw;
          margin-top: 2vw;
          margin-left: 2vw;
        }

        /deep/ .el-button {
          width: 50%;
          height: 8vw;
          line-height: 0vw;
          margin-top: 5vw;
          border-radius: 1vw;

          span {
            font-size: 3vw;
            margin: 0 !important;
          }
        }
      }
    }

    /*  第二页 第三页*/

    .introduce {
      width: 100vw;
      box-sizing: border-box;
      height: 100%;
      padding: 0% 10vw;
      box-shadow: 0px 0px 0px #727272;
      margin-top: 5vw;

      .introduceTitle {
        opacity: 1;
        margin-top: 4vw;
        text-align: center;

        h3 {
          font-size: 5vw;
          font-weight: 600;
          color: #25282B;
        }

        h4 {
          font-size: 2vw;
          font-weight: 600;
          color: #25282B;
        }

        div {
          margin-top: 4vw;
          line-height: 6vw;
          font-size: 3vw;
          color: black;
          font-weight: 400;
          width: 98%;
        }

      }

      .introduceImg {
        text-align: center;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .introduceImg2 {
        background-repeat: no-repeat;
        background-size: 68%;

        img {
          width: 100%;
        }
      }

      .el-col-10 {
        height: 100%;
      }

      .introduceFooter {
        margin-top: 2vw;
        width: 100%;
        height: 50%;
        transform: translateY(50vh);
        -webkit-animation: topBox 1.2s ease-in 1 forwards;
        -webkit-animation-delay: 1.2s;
        margin-top: 6vw;
        /*background: #0df2fa;*/

        h3 {
          font-size: 7vw;
          font-weight: 700;
          line-height: 7vw;
          height: 8vw;
          display: inline-block;
          color: #d4d4d4;
        }

        p {
          font-size: 3vw;
          font-weight: 400 !important;
        }

        img {
          width: 7vw;
          margin-left: 1vw;
          display: inline-block;
          margin-top: -14vw !important;
        }

        .el-col-8 {
          transform: scale(1);
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0);
          transition: transform 0.5s;
        }

        .el-col-8:hover {

          transform: scale(1.1);
          /*box-shadow: 0px 0px 5px  rgba(0,0,0,1);*/
        }

      }

      .introduceFooter2 {
        margin-top: 1px;
        width: 100%;
        height: 50%;


        h3 {
          font-size: 5vw;
          font-weight: 700;
          line-height: 7vw;
          height: 7vw;
          display: inline-block;
          color: #d4d4d4;
          vertical-align: middle;
        }

        img {
          width: 7vw;
          height: 7vw;
          margin-left: 0vw;
          vertical-align: bottom;
          display: inline-block;
        }

        li {
          margin-top: 4vw;
          transform: scale(1);
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0);
          transition: transform 0.5s;
        }

        li:hover {

          transform: scale(1.2);
          /*box-shadow: 0px 0px 5px  rgba(0,0,0,1);*/
        }

      }
    }

    /*  第 四页*/

    .product_center {
      width: 100vw;
      overflow: hidden;
      height: 100vw;
      .productTitle {
        width: 80%;
        height: 14%;
        margin-top: 8%;
        margin-left: 10%;
        text-align: center;

        h3 {
          font-size: 30px;
          color: #25282b;
          font-weight: 600;
        }

        h4 {
          font-size: 15px;
          color: #25282b;
          margin-bottom: 20px;
        }

      }

      .productBox {
        width: 100vw;
        height: 50vh;
        margin: auto;
        box-sizing: border-box;
        /deep/ .el-carousel__indicator--horizontal {
          display: inline-block;
          padding: 0px 4px;

          /deep/ .el-carousel__button {
            width: 2.5vw;
          }
        }

        /*background: white;*/
        position: relative;
      }
    }
    /*  第五页*/
    .application {
      width: 100vw;
      height: 100%;
      .applicationTitle {
        width: 80%;
        height: 10%;
        margin-top: 8%;
        margin-left: 10%;
        text-align: center !important;

        h3 {
          font-size:7vw;
          color: #25282b;
          font-weight: 600;
        }

        h4 {
          font-size: 4vw;
          color: #25282b;
          margin-bottom: 20px;
        }

      }
      .applicationCard {
        margin: 10vw 0;
        width: 100%;
       padding: 5vw 0;
        box-shadow: 0px 0px 2vw #b8b8b8;
        border-radius:0px !important;
        text-align: center;

        .applicationCardPic {
          width: 80% !important;
          height: 50% !important;
          box-sizing: border-box;
          overflow: hidden;
          margin-left: 10%;
          border-radius: 2vw ;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .applicationCardText {
          width: 90%;
          margin: auto;
          height: 27%;
          overflow: hidden;

          h3 {
            font-size: 18px;
            color: #292929;
            font-weight: 600;
            text-align: center;
            margin-top: 2%;
          }

          p {
            text-indent: 2em;
            font-size: 14px;
            line-height: 20px;
            color: #25282b;
            margin-top: 5px;
            width: 100%;
            height: 40px;
            text-indent: 2em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          span {
            margin-top: 1%;
            display: inline-block;
            margin-left: 70%;
            color: #43d5fd;
            text-align: right;
            cursor: pointer;
          }
        }

      }
      .applicationContent{

      }
    }

    /*  第六页*/

    .contactUs {
      width: 100vw;
      height: 100%;
      .contactUsTitle {
        width: 80%;
        height: 10%;
        margin-top: 8%;
        margin-left: 10%;
        text-align: center;

        h3 {
          font-size: 30px;
          color: #25282b;
          font-weight: 600;
        }

        h4 {
          font-size: 15px;
          color: #25282b;
          margin-bottom: 20px;
        }

      }

      .contactUsContent {
        width: 100%;
        height: 70%;
.contactUsContentTop{
  box-sizing: border-box;
  padding-left: 25%;
}
        .contactUsContentMap{
          width: 100%;
          box-sizing: border-box;
          .map {
            width: 90%;
            margin-left: 5%;
            height: 70vw;
            border: 1px solid black;
          }
        }
        .contactUsFooter {

          input {
            margin-bottom: 20px;
            background: none;
            border: 1px solid #4e4e4e;

          }

          .el-col-16 {
            /*width: 100%;*/
            height: 290px;

            textarea {
              width: 100%;
              height: 60%;
              background: none;
              margin-bottom: 30px !important;
              border: 1px solid #4e4e4e;

            }

            .el-button {
              background: #0d9efa;
              color: white;
              font-weight: 600;
              font-size: 120%;
              border: none;
            }

          }
        }
      }
    }
  }
</style>
